import { createApp } from 'vue';
import App from './App.vue';
import 'primevue/resources/themes/aura-light-green/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import Tooltip from 'primevue/tooltip';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

const app = createApp(App);
app.use(PrimeVue);
app.directive('tooltip', Tooltip);
app.use(ToastService);

app.mount('#app');